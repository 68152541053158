@import '_base.scss';

:global {
    html,
    body {
        height: 100%;
        width: 100%;
    }

    #app {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        width: 100%;
    }

    svg,
    svg path {
        fill: currentcolor;
    }

    .center-table {
        table td {
            text-align: center;
        }

        .ant-table-thead > tr > th {
            text-align: center;
        }
    }
}

@import '_base.scss';

.pageLoading {
    align-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

.spin {
    display: initial;
}
